import React, { useEffect } from 'react';
import Link from '../../../SEO/Link';
import PremiumPlacementImage from '../PremiumPlacementImage';
import PropTypes from 'prop-types';
import './styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setProductImpression,
  addEcommerceTracking
} from '../../../../store/actions/dataLayer';

const listingType = 'premium placement';

const PremiumPlacement = (props) => {
  const { id, container, region, addEcommerceTracking, variant } = props;
  useEffect(() => {
    props.setProductImpression(
      id,
      listingType,
      null,
      null,
      null,
      container,
      region
    );
  }, []);

  const clickTracker = () => {
    addEcommerceTracking(id, listingType);
  };

  const listingAnchorParamsDefault = {
    'data-reporting-click-product-id': id,
    'data-reporting-click-listing-type': listingType,
    'data-reporting-rank': 'n/a',
    'data-reporting-page': 'n/a',
    'data-reporting-listing-container': container,
    'data-reporting-listing-region': region
  };

  const premiumPlacementAdVariant1 = (
    clickTracker,
    listingAnchorParamsDefault
  ) => {
    const altText = 'Premium placement';
    return (
      <li
        className="premium-placement variant1"
        id="premium-placement-variant1"
      >
        <Link
          href={props.boatLink}
          onClick={clickTracker}
          {...listingAnchorParamsDefault}
        >
          <div className="pp-container">
            <div className="pp-boat-img">
              <PremiumPlacementImage
                title={props.title}
                image={props.mediaUrl}
                resize={{ width: 479, height: 250 }}
                altText={altText}
              />
            </div>
            <div className="pp-boat-info">
              <div className="pp-boat-tp">
                <div className="pp-boat-title">{props.title}</div>
              </div>
              <div className="info">
                <div className="pp-sb-info">
                  Offered by{' '}
                  <span className="pp-sb-dealer">{props.sellerName}</span>
                </div>
              </div>
              <div className="pp-boat-details">
                <p>{props.description}</p>
              </div>
              <div className="rm">{props.showMore}</div>
              <div className="pp-dealer-logo">
                <img src={props.sellerLogo} alt="Dealer Logo" />
              </div>
            </div>
          </div>
        </Link>
      </li>
    );
  };
  return variant === 1 ? (
    premiumPlacementAdVariant1(clickTracker, listingAnchorParamsDefault)
  ) : (
    <li className="premium-placement" id="premium-placement">
      <Link
        href={props.boatLink}
        onClick={clickTracker}
        {...listingAnchorParamsDefault}
      >
        <div className="pp-container">
          <div className="pp-boat-info">
            <div className="pp-boat-tp">
              <div className="pp-boat-title">{props.title}</div>
            </div>
            <div className="pp-boat-details">
              <p>{props.description}</p>
            </div>
            <div className="rm">{props.showMore}</div>
            <div className="pp-sb-dealer">{props.sellerName}</div>
            <div className="pp-boat-btns">
              <div className="pp-dealer-logo">
                <img src={props.sellerLogo} alt="Dealer Logo" />
              </div>
              <div className="pp-contact">
                <span className="pp-contact-btn">{props.contact}</span>
              </div>
            </div>
          </div>
          <div className="pp-boat-img">
            <PremiumPlacementImage
              title={props.title}
              image={props.mediaUrl}
              altText={props.title}
            />
          </div>
        </div>
      </Link>
    </li>
  );
};

PremiumPlacement.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  mediaUrl: PropTypes.string,
  sellerName: PropTypes.string,
  sellerLogo: PropTypes.string,
  boatLink: PropTypes.string,
  showMore: PropTypes.string,
  contact: PropTypes.string,
  id: PropTypes.number,
  container: PropTypes.string,
  region: PropTypes.string,
  setProductImpression: PropTypes.func.isRequired,
  addEcommerceTracking: PropTypes.func.isRequired,
  variant: PropTypes.number
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      setProductImpression,
      addEcommerceTracking
    },
    dispatch
  )
)(PremiumPlacement);
