import React from 'react';
import PropTypes from 'prop-types';
import { useLoadedImage } from '../../utils/listingHelper';

const SafeImage = ({src, alt, className}) => {
  const safeImage = useLoadedImage(src);
  if (!safeImage) {
    return null;
  }
  return (
    <img src={src} alt={alt} className={className}/>
  );
};

SafeImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default SafeImage;
