import parse from 'qs/lib/parse';
import pickBy from 'lodash/pickBy';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

const setToTruishValueIfPresent = (k, v) => ({
  [k]: (v === '' ? 'true' : v)
});

const cleanParams = (validKeys) => (params) => {
  return reduce(pickBy(params, (_, k) => validKeys.includes(k)), (acc, v, k) => {
    return { ...acc, ...setToTruishValueIfPresent(k, v) };
  }, {});
};

const featureToggles = [
  'enablePremiumPlacement'
];

const testParams = [
  'testIP',
  'debug_prebid'
];

export const getParamsFromLocationAndHistoryQueryString = ({ location, history}) => {
  const fromCurrentLocation = parse(get(location, 'search', '').slice(1));
  const fromHistory = parse(get(history, 'location.search', '').slice(1));
  const clean = cleanParams([
    ...featureToggles,
    ...testParams
  ]);
  return {
    ...clean(fromHistory),
    ...clean(fromCurrentLocation),
  };
};

export default {
  getParamsFromLocationAndHistoryQueryString: getParamsFromLocationAndHistoryQueryString
};
